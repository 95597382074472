import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      loop: true,
      speed: 450,
      autoplay: {
        delay: 3000,
      },
      slidesPerView: 1.2,
      spaceBetween: 10,

      breakpoints: {
        512: {
          slidesPerView: 2,
        },
        720: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 1.4,
          spaceBetween: 15,
        },
        1400: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      },
    })
  }
}
