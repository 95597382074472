import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['backgroundSliderContainer', 'miniSliderContainer', 'prevButton', 'nextButton']
  connect() {
    var miniSlider = new Swiper(this.miniSliderContainerTarget, {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 10,
      allowTouchMove: false,
      speed: 450,

      breakpoints: {
        1330: {
          slidesPerView: 3,
        },
        2024: {
          slidesPerView: 4,
        },
      },
    })

    var backgroundSlider = new Swiper(this.backgroundSliderContainerTarget, {
      loop: true,
      slidesPerView: 1,
      speed: 450,

      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      breakpoints: {
        1024: {
          allowTouchMove: false,
        },
      },
    })

    // Added the calls this way to allow oth sliders to move at the same time.
    this.nextButtonTarget.addEventListener('click', function () {
      console.log('Next Slide')
      miniSlider.slideNext()
      backgroundSlider.slideNext()
    })

    this.prevButtonTarget.addEventListener('click', function () {
      console.log('Prev Slide')
      miniSlider.slidePrev()
      backgroundSlider.slidePrev()
    })
  }
}
