import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['prevButton', 'nextButton', 'container']
  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      speed: 450,
      autoplay: {
        delay: 3000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      reverseDirection: true,
      slidesPerView: 1,
      spaceBetween: 25,
      navigation: {
        prevEl: this.prevButtonTarget,
        nextEl: this.nextButtonTarget,
      },
    })
  }
}
