import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['swiperContainer', 'pagination']
  connect() {
    let swiper = new Swiper(this.swiperContainerTarget, {
      loop: true,
      autoplay: {
        delay: 8000,
      },
      speed: 800,
      pagination: {
        el: this.paginationTarget,
        type: 'bullets',
      },
    })
  }
}
