import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['mobileNav']
  connect() {
    document.documentElement.style.setProperty('--fixed-header-height', `${this.element.offsetHeight}px`)

    document.addEventListener('scroll', () => {
      if (window.pageYOffset > 30) {
        this.element.classList.add('homepage-scrolled')
      } else {
        this.element.classList.remove('homepage-scrolled')
      }
    })
  }

  toggleNav() {
    this.mobileNavTarget.classList.toggle('hidden')
  }
}
