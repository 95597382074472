import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'burger', 'close']
  connect() {}

  toggleNav() {
    this.burgerTarget.classList.toggle('hidden')
    this.closeTarget.classList.toggle('hidden')
    this.containerTarget.classList.toggle('hidden')
  }
}
