import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  connect() {
    let swiper = new Swiper(this.element, {
      loop: true,
      autoplay: {
        delay: 3000,
      },
    })
  }
}
